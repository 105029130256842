$mobile-resolution: 600px;
$global-font-family: 'Montserrat', 'Roboto', sans-serif;
$transparent-50: 80;

@function get-transparent-50-color($color) {
  @return unquote($color + '' + $transparent-50);
}
 
// primary
$primary-color: #FF8205;
$primary-color-400: #FF952B;
$primary-color-300: #FFA850;
$primary-color-200: #FFC182;
$primary-color-100: #FFDAB4;
$primary-color-50: #FFF0E1;
$primary-color-20: #fff6ee;
$primary-color-50-transparent: rgb(255 242 225 / 34%);

// secondary
$secondary-color: #7093F7;
$secondary-color-400: #85A3F8;
$secondary-color-300: #9BB3F9;
$secondary-color-200: #B8C9FB;
$secondary-color-100: #D4DFFD;
$secondary-color-50: #EEF2FE; 

// dark
$dark-color-500: #24232B;
$dark-color-400: #45444B;
$dark-color-300: #66656B;
$dark-color-200: #929195;
$dark-color-100: #BDBDBF;
$dark-color-50: #E5E5E6;

// light
$light-color-500: #BCBDCD;
$light-color-400: #C7C7C7;
$light-color-300: #D1D1D1;
$light-color-200: #DEDEDE;
$light-color-100: #EBEBEB;
$light-color-50: #F7F7F7;

// red
$error-color: #F52B2B;
$red-color-500: #DB2435;
$red-color-400: #E04553;
$red-color-300: #E66672;
$red-color-200: #ED929A;
$red-color-100: #F4BDC2;
$red-color-50: #FBE5E7;

// green
$green-color-500: #2DA051;
$green-color-400: #4DAE6B;
$green-color-300: #6CBD85;
$green-color-200: #96D0A8;
$green-color-100: #C0E3CB;
$green-color-50: #E6F4EA;

// other
$white-color: #fff;
$light-blue-color: #f1f4fe;
$light-blue-color-transparent-50: get-transparent-50-color($light-blue-color);
$text-gray-color: #767676;
$sidebar-color: #302D40;
$disabled-color: #BDBDBD;
$border-color: #EBEBEB;
$background-color: #F8F8F8;
$light-gray-color: #E5E5E5;
$background-light-gray-color: #f9f9f9;
$gray-color: #b3b3b3;
$blue-color: #70C6F7;
$yellow-color-500: #E9E241;
$yellow-color-100: #ebe697;
$yellow-color-50: #ebe697;
$yellow-color: #ec932d;
$teal-color-500: #6CE8D9;
$purple-color-50: #F3E9FC;

// gradient
$gradient: linear-gradient(116.58deg, #F8A453 -1.06%, #F26431 98.82%);
$shadow4: 0 2px 4px rgb(45 42 129 / 10%);

// screen
$tablet-width: 1200px;
$less-tablet-width: 1000px;
$mobile-width: 767px;
$small-mobile-width: 600px;
$unique-btn-color: rgb(255 130 5 / 8%);

:export {
  primaryColor: $primary-color;
  primaryColor400: $primary-color-400;
  primaryColor50: $primary-color-50;
  secondaryColor: $secondary-color;
  secondaryColor100: $secondary-color-100;
  secondaryColor50: $secondary-color-50;
  sidebarColor: $sidebar-color;
  disabledColor: $disabled-color;
  borderColor: $border-color;
  backgroundColor: $background-color;
  darkColor100: $dark-color-100;
  darkColor200: $dark-color-200;
  darkColor500: $dark-color-500;
  textGrayColor: $text-gray-color;
  greenColor500: $green-color-500;
  greenColor50: $green-color-50;
  redColor500: $red-color-500; 
  whiteColor: $white-color;
  lightColor500: $light-color-500;
  lightColor200: $light-color-200;
  lightColor100: $light-color-100;
  lightColor50: $light-color-50;
  lightBlueColor: $light-blue-color;
  blueColor: $blue-color;
  tealColor500: $teal-color-500;
  yellowColor500: $yellow-color-500;
  boxShadow4: $shadow4;
  redColor100: $red-color-100;
  secondaryColor200: $secondary-color-200;
}

.widget-attachments {
  justify-content: flex-start;

  &__link {
    color: $dark-color-500 !important;
    text-decoration: none !important;
    display: block;
    cursor: pointer;

    &:hover {
      color: $primary-color !important
    }
  }

  &__time {
    font-size: 13px !important;
    color: $text-gray-color !important;
    padding-right: 5px;
  }

  &__author {
    font-size: 13px !important;
    color: $text-gray-color !important;
  }

  &__preloader {
    margin: 20px 0;
    font-size: 14px !important;
    color: $dark-color-500;

    &-files {
      font-weight: 600;
      padding-right: 5px;
    }
  }

  &__dropzone {
    height: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed $border-color;
    width: 100%;
    border-radius: 2px;
    padding: 22px;
    margin: 25px 20px;
    transition: all .3s ease-in-out;

    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 210px;
    }

    &-btn {
      background-color: $secondary-color-50 !important;
      color: $dark-color-500 !important;
      width: 176px !important;
      border-radius: 4px !important;
      font-weight: 600 !important;

      &:hover {
        background-color: $secondary-color-100 !important;
      }
    }

    &-text {
      padding-left: 20px;
      color: $dark-color-500;
      font-weight: 500;
    }

    &:hover {
      background-color: $primary-color-50;
      border: 1px dashed $primary-color-100;
      cursor: pointer;
    }

    &.active {
      border-color: $green-color-200;
      background-color: $green-color-50;
    }
    
    &.accept {
      border-color: $green-color-200;
      background-color: $green-color-50;
    }
    
    &.reject {
      border-color: $red-color-200;
      background-color: $red-color-50;
    }
  }

  &-tab {
    &__data {
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 4px;
      background-color: $background-light-gray-color;
      margin-left: 5px;

    }
  }

  &__list {
    max-height: 310px;
    height: 310px;
    overflow: auto;
    margin: 30px 0 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $border-color
      }
    }

    &__view {
      max-height: 520px;
      height: 520px;
    }
  }

  .MuiOutlinedInput-root {
    padding: 0 !important;
    margin-top: 0 !important;

  &.Mui-focused fieldset {
    border-width: 1px !important;
  }

    &.MuiListItemText-multiline {
      max-width: 100% !important;
      text-overflow: ellipsis !important;
    }
  }

  .MuiTab-root {
    padding-right: 10px;
    padding-left: 10px;
  }

  .tabs-tab-component__title {
    font-size: 13px !important;
  }
}










